::-webkit-scrollbar { 
  display: none; 
}

body { 
  width: 100vw; 
  height: 100vh; 
}

#root { 
  width: 100%; 
  height: 100%; 
  display: flex; 
  flex-direction: column; 
}

.OT_name {
  letter-spacing: 0px;
}